<template>
  <v-app>
    <Header />
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/header/Header.vue";

export default {
  name: "empty",
  components: {Header}
}
</script>

<style scoped>

</style>